import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Breadcrumb, Row, Col, Accordion, Card, Button } from 'react-bootstrap'
import { Section } from '../components/Section'
import PatternImg from "../images/line-pattern.png"
 
function activeAccordion(e) {
  var elems = document.querySelectorAll(".accordian-item");
  [].forEach.call(elems, function(el) {
    el.classList.remove("active");
  });
  e.target.classList.add("active");
}

const FAQ = () => (
  <Layout>
    <SEO title="FAQ" />
    <Section bg={`url(${PatternImg})`}>
      <Container className='position-relative'>
        <Breadcrumb className='beadcrumbs-outer'>
          <Breadcrumb.Item href="/">Home</Breadcrumb.Item>
          <Breadcrumb.Item active>Faq</Breadcrumb.Item>
        </Breadcrumb>
      </Container>
    </Section>
    <Section className="section faq-us" pt='100px' pb='70px'>
      <Container>
        <Row>
          <Col lg={{ span: 8, offset: 2 }}>
            <div className='section-heading text-center'>
              <h2 className='section-title'>Frequently Asked Questions</h2>              
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
    <Section bg={`url(${PatternImg})`} className='section faq-us-tabs' pt='50px' pb='50px'>
      <Container>        
      <Accordion defaultActiveKey="0">
        <Card>
          <Card.Header>
            <Accordion.Toggle className='accordian-item' onClick={activeAccordion.bind(this)} as={Button} variant="link" eventKey="0">
            <span className='toggle-plus'></span> What style of buildings do you offer?</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="0">
            <Card.Body>You’ve probably guessed from our name that we provide metal carports … and you’re right! We offer standard, double, and triple wide carports of various designs, and you can choose from among three roof styles – the regular roof, the A-frame horizontal (or boxed eave) roof, and our premium option, the vertical roof. But Carports R Us does much more than just carports. We also manufacture and install garages, barns, workshops, commercial facilities, and other custom steel structures. As we like to say at Carports R Us, if you can dream it, we can build it!</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} className='accordian-item' onClick={activeAccordion.bind(this)} variant="link" eventKey="1">
            <span className='toggle-plus'></span>How long will it take to install my building?</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="1">
            <Card.Body>One advantage of choosing a custom metal building from Carports R Us is that it can typically be installed much faster than a stick-build or other comparable building. Once your order is processed, we’ll get to work on custom fabricating each individual steel component for your building. Once the materials are delivered to your site, we can usually get buildings installed in a matter of days. Individual lead times can vary based on the size of your building, your particular installation location, and the current weather conditions. Even so, Carports R Us has some of the shortest lead times in the industry!</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} className='accordian-item' onClick={activeAccordion.bind(this)} variant="link" eventKey="2">
            <span className='toggle-plus'></span>Can I customize a metal building?</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="2">
            <Card.Body>You sure can! The style, design, and width-length-height dimensions of your building are completely up to you. You select your choice of roof style, and you also decide whether you want to leave your building open, partially enclosed, or fully enclosed. You can put garage doors, frame outs, walk-in doors, or windows wherever you like, and you also choose the colors for your roof, siding, and trim. We can also certify your building to meet any minimum required wind and/or snow loads, and you can also decide which gauge of steel you prefer for the framing and which gauge for the metal sheeting. Want to talk more about customization options? Just give us a call!
</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} className='accordian-item' onClick={activeAccordion.bind(this)} variant="link" eventKey="3">
            <span className='toggle-plus'></span>Do I need a building permit?</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="3">
            <Card.Body>That depends. Some areas do require building permits for metal structures, while a permit isn’t necessary in other areas. You should check with your local inspector’s office to learn about the regulations and standards in your area. If you have other questions, just reach out to us and we’ll be glad to try and help! Note that if a permit is required for your site, you’ll need to secure that before we arrive to install your building.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} className='accordian-item' onClick={activeAccordion.bind(this)} variant="link" eventKey="4">
            <span className='toggle-plus'></span>How should I prepare my site for steel building installation?</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="4">
            <Card.Body>First, make sure to obtain any needed permits, if they’re required in your area. Next, you need to ensure that your site is level before we arrive with your building. We can sometimes install your building “as is” on an unleveled lot, but we don’t recommend this as we can’t guarantee the longevity of your building if installed on an unlevel site. Also, please mark any underground cables, gas lines, or other utility lines. Carports R Us, Inc. will not be held responsible for any damages to underground utility lines. If you’re going to pour a pad, it should be the same width as the building and one foot shorter than the roof length. For example, if the unit size is 26’x31’, your slab should measure 26’x30’.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} className='accordian-item' onClick={activeAccordion.bind(this)} variant="link" eventKey="5">
            <span className='toggle-plus'></span>What are gauges? Are there any gauge options?</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="5">
            <Card.Body>Metal gauge is simply a measurement of the steel component’s thickness. For steel framing, you can choose between our standard 14-gauge, or our sturdier 12-gauge. Similarly, for the sheet metal we have standard 29-gauge, or you can upgrade to our thicker 26-gauge.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} className='accordian-item'  onClick={activeAccordion.bind(this)}  variant="link" eventKey="6">
            <span className='toggle-plus'></span>What type of warranty is provided by Carports R Us?
</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="6">
            <Card.Body>At Carports R Us, we do back our buildings with a limited warranty. Our 14-gauge units carry a 1 year no rust warranty. If your frame should rust during the first year, we will repair and/or replace it. Our stronger 12-gauge units carry a 20 year no rust warranty.</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} className='accordian-item'  onClick={activeAccordion.bind(this)}  variant="link" eventKey="7">
            <span className='toggle-plus'></span>Are there any financing options available?</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="7">
            <Card.Body>Carports R Us does provide both financing and rent-to-own options, based upon the total purchase price of your building. If you’d like to learn more about our building financing options, just reach out to us!</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} className='accordian-item'  onClick={activeAccordion.bind(this)}  variant="link" eventKey="8">
            <span className='toggle-plus'></span>Do you require a down payment?</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="8">
            <Card.Body>Carports R Us doesn’t require you to pay the full purchase price of your building up front, but we do need to receive a down payment before we can process your building order. The typical down payment amount is usually between 10-15% of the total building price, before taxes. Your building balance isn’t due until we come out for your delivery and installation. Speaking of which, we INCLUDE delivery and installation of your building within our service area, all at no extra cost to you!</Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Card.Header>
            <Accordion.Toggle as={Button} className='accordian-item'  onClick={activeAccordion.bind(this)}  variant="link" eventKey="9">
            <span className='toggle-plus'></span>Do you offer metal building kits?</Accordion.Toggle>
          </Card.Header>
          <Accordion.Collapse eventKey="9">
            <Card.Body>Yes we do! We normally include delivery and installation with purchase, but we also make self-install building kits available as well. If you’re a DIYer who likes to save a little extra money by doing things yourself, we can provide you with a custom-engineered metal building kit that you can install on your own at a discount.</Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      </Container>
    </Section>
  </Layout>
)

export default FAQ
